import React from 'react'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import NavLink from 'components/nav-link'
import { useFooterItems } from 'utils/use-footer-items'
import { useSiteMetadata } from 'utils/use-site-metadata'

import './footer.scss'


const Basic = ({ author }) => {
  const navItems = useFooterItems()
  return (
    <footer className="mt-auto">
      <Navbar id="footer" bg="body" variant="dark" className="pb-1">
        <div className="container d-flex flex-column flex-lg-row justify-content-between align-items-center">
          <Nav className="d-none d-lg-flex">
            {navItems.map((item, index) => (
              <NavLink key={index} href={item.to} className="text-nowrap">
                {item.label}
              </NavLink>
            ))}
          </Nav>
          <Navbar.Text className="text-nowrap">
            Copyright &copy; {new Date().getFullYear()}
            {author && <span className="d-none d-sm-inline">{' ' + author}</span>}
          </Navbar.Text>
        </div>
      </Navbar>
    </footer>
  )
}

const Page = ({ author }) => {
  return (
    <footer className="mt-auto">
      <Navbar id="footer" bg="body" variant="dark" className="pb-1 justify-content-center">
        <Nav>
          <Navbar.Text className="text-nowrap">
            Copyright &copy; {new Date().getFullYear()}
            {author && <span className="d-none d-sm-inline">{' ' + author}</span>}
          </Navbar.Text>
        </Nav>
      </Navbar>
    </footer>
  )
}

interface Props {
  author: string
  variant: string
}

const Footer = ({ author, variant }: Props) => {
  const { siteAuthor } = useSiteMetadata()
  const pageAuthor = author ?? siteAuthor
  if (variant == 'page') return <Page author={pageAuthor} />
  return <Basic author={pageAuthor} />
}

export default Footer
