import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import NavLink from 'components/nav-link'
import { useHeaderItems } from 'utils/use-header-items'

import './header.scss'

const Basic = ({ location }) => {
  const navItems = useHeaderItems()
  const renderHomeItem = (location) => {
    const { pathname } = location
    return (
      <NavLink href={pathname == '/' ? '/#home' : '/'} className="d-none d-md-block">
        Nabla
      </NavLink>
    )
  }
  return (
    <header>
      <a id="home" />
      <div className="d-none d-md-block">
        <Navbar id="header" fixed="top" bg="body" variant="dark">
          <Nav className="container d-flex flex-column flex-md-row justify-content-around">
            {renderHomeItem(location)}
            {navItems.map((item, index) => (
              <NavLink key={index} href={item.to} className="d-none d-md-block">
                {item.label}
              </NavLink>
            ))}
          </Nav>
        </Navbar>
      </div>
    </header>
  )
}

interface Props {
  location: Location
  variant: string
}

const Header = ({ location, variant }: Props) => {
  return <Basic location={location} />
}

export default Header
